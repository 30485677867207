@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
.BaseWrapper {
    display: inline-block;
    width: 72px;
    height: 36px;
}

.Base {
    display: inline-block;
    width: 72px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
    vertical-align: top;
    position: relative;
}

.Rotated svg {
    transform: rotate(180deg);
    transform-origin: 36px 18px;
}

.ShapeLabel {
    color: #000;
    position: relative;
    top: -47px;
    left: 22px;
    filter: drop-shadow( 1px 1px 1px rgba(255, 255, 255, .7));
}

.Rotated .ShapeLabel {
    position: relative;
    top: -47px;
    left: 36px;
}

.ShapeWrapper {
    position: relative;
    width: 0px;
    height: 0px;
    opacity: 0;
    transition: opacity 0.1s;
}

.ShapeWrapper.Visible {
    opacity: 1;
}

.BaseShape {
    width: 72px;
    height: 36px;
}

.Base {
    outline: none;
    user-select: none;
}

.A svg path {
    fill: var(--a-color);
}

.C svg path {
    fill: var(--c-color);
}

.G svg path {
    fill: var(--g-color);
}

.T svg path {
    fill: var(--t-color);
}

.Base.focused svg path {
    stroke-width: 2px;
}

.Base.focused.A svg path,
.Base.A:hover svg path {
    fill: var(--a-highlight-color);
}

.Base.focused.C svg path,
.Base.C:hover svg path {
    fill: var(--c-highlight-color);
}

.Base.focused.G svg path,
.Base.G:hover svg path {
    fill: var(--g-highlight-color);
}

.Base.focused.T svg path,
.Base.T:hover svg path {
    fill: var(--t-highlight-color);
}

.swapparent {
    position: relative;
    display: inline-block;
    width: 0px;
    height: 0px;
}