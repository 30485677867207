.Swap {
    width: 24px;
    height: 24px;
    transform-origin: center;
    background: none;
}

.swap-focused {
    position: absolute;
    display: inline-block;
    padding: 0;
    color: #fff;
    font-size: 20px;
    text-align: center;
    border: 0px;
    border-radius: 8px;
    cursor: pointer;
    margin: 0px;
    z-index: 2;
}

.swap-focused:hover {
    background-color: #998;
}

.swap-hidden {
    display: none;
}

.swap-left {
    transform: rotate(180deg);
    left: -94px;
    top: -9px;
}

.swap-right {
    left: 5px;
    top: -9px;
}

.swap-up {
    transform: rotate(-90deg);
    left: -50px;
    top: -42px;
}

.swap-down {
    transform: rotate(90deg);
    left: -50px;
    top: 22px;
}