.BasePairWrapper {
    width: 360px;
    height: 36px;
    margin: 30px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.RotateWrapper {
    clear: left;
    float: left;
    position: relative;
    width: 360px;
    height: 0;
    display: flex;
    justify-content: center;
}

.Narrow {
    width: 300px;
}

.RotateWrapper button {
    position: relative;
    top: -36px;
}

.RotateButton {
    display: flex;
    margin: 0;
    width: 36px;
    height: 36px;
    background-image: radial-gradient(60% 50%, #eee 0%, #777 80%);
    border: 0;
    border-radius: 12px;
    margin: 0 6px;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 1s;
    cursor: pointer;
}

.RotateButton img {
    height: 50%;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 40%));
}

.RotateButton.Solved {
    opacity: 0;
}