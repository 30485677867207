@import url('https://fonts.googleapis.com/css2?family=Sigmar+One&display=swap');
.Game {
    display: flex;
    flex-direction: column;
    background-image: url(../public/dna_game_bkgnd.png);
    background-size: contain;
    background-repeat: no-repeat;
    width: 360px;
    height: var(--game-height);
    margin: 0;
    padding: 0;
    opacity: 1;
    transition: all 1.4s ease-in-out;
    position: relative;
}

.Game.Closing,
.Game.Opening {
    opacity: 0;
    transform: rotateY(90deg);
}

.TopArea {
    display: flex;
    flex-grow: 0;
    width: 360px;
    height: 100px;
    justify-content: space-between;
    align-items: flex-start;
}

.ValueWrapper {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ValueLabel {
    font-family: 'Sigmar One';
    font-size: 18px;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 70%));
    z-index: 1;
}

.Value {
    position: relative;
    top: -10px;
    border-radius: 8px;
    width: 60px;
    height: 30px;
    line-height: 30px;
    background-color: var(--dark-blue-bkgnd);
    font-size: 18px;
    font-family: 'Sigmar One';
    color: #fff;
    text-align: center;
}

.MessageArea {
    display: flex;
    flex-grow: 0;
    width: 360px;
    height: 50px;
    align-items: center;
    justify-content: center;
}

.Message {
    padding: 0px 6px;
    width: 300px;
    border-radius: 12px;
    line-height: 24px;
    text-align: center;
    background-color: rgb(255, 255, 255, 75%);
    font-family: 'Sigmar One';
    transition: all 0.5s ease-in-out;
    z-index: 2;
}

.Message.Hidden {
    opacity: 0;
    transform: scale(0.1);
}

.Message.Visible {
    opacity: 1;
    transform: scale(1);
}

.CodonWrapper {
    position: relative;
    width: 0px;
    height: 0px;
}

.CodonLabel {
    position: relative;
    transform: rotate(-90deg);
    top: 180px;
    left: 10px;
    font-family: 'Sigmar One';
    font-size: 18px;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 70%));
    z-index: 1;
}

.CodonGoalWrapper {
    position: relative;
    top: 88px;
    left: 16px;
    background-color: #000;
    width: 32px;
    height: 90px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.CodonGoal {
    margin: 2px;
    width: 20px;
    height: 20px;
    background-color: #2edede;
    border: 2px solid rgba(35, 93, 24, 20%);
    border-radius: 4px;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    color: #000;
}

.CodonGoal span {
    filter: drop-shadow(1px 1px 2px rgb(255, 255, 255, 100%));
    z-index: 1;
}

.CodonGoal.A {
    background-color: var(--a-color);
}

.CodonGoal.C {
    background-color: var(--c-color);
}

.CodonGoal.G {
    background-color: var(--g-color);
}

.CodonGoal.T {
    background-color: var(--t-color);
}

.BottomSpacer {
    display: flex;
    flex-grow: 1;
    width: 360px;
    height: 30px;
    justify-content: space-between;
    align-items: flex-start;
}

.PlayArea {
    display: flex;
    flex-grow: 0;
    width: 360px;
    height: 320px;
    align-items: center;
    touch-action: none;
}

.BottomArea {
    display: flex;
    flex-grow: 0;
    background-color: var(--dark-blue-bkgnd);
    width: 360px;
    height: 100px;
    border-radius: 12px;
    align-items: center;
    justify-content: space-around;
}

.MoveCountContainer {
    display: flex;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    background-color: var(--dark-blue-bkgnd);
    flex-direction: column;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 40px;
    line-height: 50px;
}

.MoveCountText {
    display: flex;
    color: #fff;
    font-size: 14px;
    line-height: 20px;
}

.ButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.IconButton {
    display: flex;
    margin: 0;
    width: 60px;
    height: 60px;
    background-image: radial-gradient(50% 40%, #e2ff3a 0%, #65d61a 100%);
    border: 2px solid rgba(35, 93, 24, 20%);
    border-radius: 12px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.IconButton:hover {
    background-image: radial-gradient(50% 40%, #e9ff6d 0%, #80e73b 100%);
}

.IconButton img {
    height: 50%;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 40%));
}

.ButtonWrapper span {
    position: relative;
    top: -2px;
    font-family: 'Sigmar One';
    font-size: 18px;
    line-height: 10px;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 70%));
}

.HelpWrapper {
    position: relative;
    width: 0px;
    height: 0px;
}

.HelpBackground {
    position: relative;
    opacity: 0;
    border-radius: 16px;
    width: 360px;
    height: var(--game-height);
    background-color: rgba(0, 0, 0, 50%);
    z-index: 9;
}

.HelpBackground.Visible {
    opacity: 1;
}

.HelpDialog {
    position: relative;
    border-radius: 8px;
    left: 20px;
    top: 20px;
    width: 320px;
    height: calc(var(--game-height) - 40px);
    background-color: #000;
    color: #fff;
    z-index: 10;
    overflow-x: hidden;
    overflow-y: auto;
    filter: drop-shadow(0px 0px 8px #000);
}

.HelpBackground p {
    margin: 20px;
    font-size: 13px;
}

.HelpBackground h2 {
    font-family: 'Sigmar One';
    font-size: 18px;
    margin: 1em 0 0 0;
}

.HelpBackground h3 {
    font-family: 'Sigmar One';
    font-size: 18px;
    margin: 0 0 0 1em;
}

.HelpBackground button {
    cursor: pointer;
    user-select: none;
}

.CenterWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.CloseWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.CloseXWrapper {
    width: 360px;
    height: 0px;
}

.CloseX {
    position: relative;
    left: 260px;
    top: -15px;
    background-color: transparent;
    color: #fff;
}

.CloseX:hover,
.CloseX:focus {
    background-color: var(--a-color);
}

.DNABaseWrapper {
    margin-top: 5px;
    flex-direction: column;
    display: flex;
}

.DNABase {
    display: flex;
    align-items: center;
    justify-content: left;
}