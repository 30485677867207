:root {
    --game-height: calc(max(620px, min(720px, 100vh)));
    --a-color: #ffe55b;
    --c-color: #cb72c0;
    --g-color: #2edede;
    --t-color: #90e41f;
    --a-highlight-color: #fff1a7;
    --c-highlight-color: #dfa9d8;
    --g-highlight-color: #70e8e8;
    --t-highlight-color: #b1ec63;
    --dark-blue-bkgnd: #0c325e;
}

.AppBackground {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(../public/page_bkgnd.jpg);
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App {
    display: flex;
    justify-content: space-between;
    height: var(--game-height);
    width: 880px;
    opacity: 0;
    transition: transform 0.7s ease-in-out, opacity 0.1s;
}

.App.Loaded {
    opacity: 1;
}

.AppZoomer {
    height: var(--game-height);
}

@media (min-height: 1000px) and (min-width: 500px) {
    .AppZoomer {
        transform: scale(1.2);
    }
}

@media (max-height: 599px) {
    .AppZoomer {
        transform: scale(0.75);
    }
}

@media (max-width: 1100px) {
    .App.Left {
        transform: translateX(260px);
    }
    .App.Right {
        transform: translateX(-260px);
    }
}

.LeftHalf {
    width: 360px;
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 8px;
}

.LeftHalf img {
    margin-top: 20px;
    width: 360px;
}

.RightHalf {
    width: 360px;
    height: var(--game-height);
}

.Subheading {
    font-family: 'Sigmar One';
    text-align: center;
    margin: 10px;
}

h2 {
    margin: 20px;
}

button {
    font-family: 'Sigmar One';
    font-size: 18px;
    font-weight: bold;
    padding: 6px 10px;
    margin: 16px 6px 0 6px;
    border: 0px;
    border-radius: 10px;
}

.AppButtonWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.AppIconButton {
    display: flex;
    margin: 0;
    width: 60px;
    height: 60px;
    background-image: radial-gradient(50% 40%, #e2ff3a 0%, #65d61a 100%);
    border: 2px solid rgba(35, 93, 24, 20%);
    border-radius: 12px;
    align-items: center;
    justify-content: center;
}

.AppIconButton img {
    width: 60%;
    height: 60%;
    position: relative;
    top: -8px;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 40%));
}

.AppButtonWrapper span {
    position: relative;
    top: -2px;
    font-family: 'Sigmar One';
    font-size: 18px;
    line-height: 10px;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 70%));
}

.DoubleHelixWrapper {
    position: relative;
    width: 0;
    height: 0;
    flex-grow: 0;
    flex-shrink: 0;
}

.DoubleHelixClip {
    position: relative;
    width: 360px;
    height: var(--game-height);
    overflow: hidden;
}

@keyframes helixdrift {
    from {
        transform: scale(1) translateY(0px) translateX(200px) rotate(95deg) rotateY(5deg) translateX(-100px);
    }
    20% {
        transform: scale(1.1) translateY(-40px) translateX(200px) rotate(100deg) rotateY(7deg) translateX(-100px);
    }
    40% {
        transform: scale(1.2) translateY(20px) translateX(200px) rotate(90deg) rotateY(12deg) translateX(-100px);
    }
    60% {
        transform: scale(1.25) translateY(80px) translateX(200px) rotate(80deg) rotateY(13deg) translateX(-100px);
    }
    80% {
        transform: scale(1.1) translateY(30px) translateX(200px) rotate(85deg) rotateY(9deg) translateX(-100px);
    }
    to {
        transform: scale(1) translateY(0px) translateX(200px) rotate(95deg) rotateY(5deg) translateX(-100px);
    }
}

.DoubleHelix {
    display: block;
    width: 1200px;
    height: 50px;
    background-image: url(images/double_helix.png);
    background-repeat: repeat-x;
    background-size: contain;
    transform-origin: top left;
    animation: 60s infinite normal helixdrift;
    animation-timing-function: ease-in-out;
    opacity: 0.4;
}

.ScrollingLevels {
    background-color: var(--dark-blue-bkgnd);
    margin-left: 10px;
    width: 335px;
    height: 200px;
    border: 1px solid #000;
    overflow-x: hidden;
    overflow-y: scroll;
    margin-bottom: 20px;
    scroll-behavior: smooth;
}

fieldset {
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-block-start: 0;
    padding-block-end: 0;
    padding-inline-start: 0;
    padding-inline-end: 0;
    border: none;
    min-inline-size: min-content;
    margin: 16px 0 4px 0;
}

legend {
    margin-left: 1em;
    font-family: 'Sigmar One';
    font-size: 18px;
    color: #fff;
}

.CompletedLevelsMessage {
    margin: 1.5em 0 1.5em 1em;
    font-family: 'Sigmar One';
    font-size: 14px;
    color: #fff;
}

.MainPageLabel {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-left: 20px;
    font-family: 'Sigmar One';
    font-size: 24px;
    line-height: 10px;
    color: #fff;
    filter: drop-shadow(1px 1px 2px rgb(0, 0, 0, 70%));
}

.Level {
    display: block;
    width: 280px;
    height: 36px;
    color: #eee;
    background-color: #7ab0ee;
    font-family: 'Sigmar One';
    font-size: 18px;
    line-height: 18px;
    padding: 9px;
    margin: 6px 0 0 20px;
    border-radius: 4px;
    transition: background-color 1s ease;
}

.CheckWrapper {
    width: 0;
    height: 0;
    position: relative;
    opacity: 0;
    transition: opacity 1s;
}

.Level.Solved .CheckWrapper {
    opacity: 1;
}

.Level img {
    position: relative;
    top: -42px;
    width: 26px;
    height: 26px;
}

.Level.Solved {
    background-color: #090;
    color: #fff;
}

.Level.Next {
    color: #333;
    background-color: #ddd;
}

.Level.Next:hover {
    background-color: #fff;
}

.PickerWrapper {
    border-radius: 8px;
    width: 335px;
    margin-left: 10px;
    background-color: var(--dark-blue-bkgnd);
    height: 60px;
    border: 1px solid #000;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PickerWrapper button {
    margin: 0 8px;
    font-family: 'Sigmar One';
    font-size: 18px;
    color: #000;
    background-color: #eee;
}

.LinkButton {
    padding-left: 20px;
}

.LinkButton button,
.ScrollingTextView button {
    font-family: 'Sigmar One';
    font-size: 18px;
    color: #000;
    background-color: #eee;
}

.LeftHalf button,
.ScrollingTextView button {
    transition: opacity 0.5s;
    cursor: pointer;
    user-select: none;
}

.LeftHalf button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.Preloader {
    width: 0;
    height: 0;
    background-image: url(../public/dna_game_bkgnd.png);
}

.ScrollingTextView {
    border-radius: 8px;
    color: #fff;
    background-color: var(--dark-blue-bkgnd);
    width: 360px;
    height: var(--game-height);
    overflow-x: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    opacity: 0;
    transition: opacity 1s;
}

.ScrollingTextView p {
    margin: 0 1em 1em 1em;
}

.ScrollingTextView.Visible {
    opacity: 1;
}

.ScrollingTextView h3 {
    margin-left: 1em;
    color: #fff1a7;
}

.CloseWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}