.BasePairMatch {
    display: inline-block;
    height: 3px;
    position: relative;
    top: 17px;
}

.BasePairMatchLeft {
    margin-left: 6px;
}

.BasePairMatchRight {
    margin-right: 6px;
}

.BasePairMatchLeft,
.BasePairMatchRight {
    width: 18px;
    background-color: #13508b;
}

.BasePairMatchCenter {
    width: 30px;
    background-color: #13508b;
}

.BasePairMatchEmpty {
    width: 30px;
    background-color: none;
}